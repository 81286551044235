import * as React from 'react'
import PropTypes from 'prop-types';
import 'focus-visible'
import Footer from '../global/footer'
import SEOComponent from "./seo"
import '../../styles/site.scss'
import Navigation from './navigation';
import { Link } from "gatsby"
import CookieConsent from "react-cookie-consent";
import { pages } from '../../site-config'

export const Layout = ({ pageTitle, meta, metaTitle, metaDescription, ogType, shareImage, children }) => {
    return (
        <>
            <SEOComponent pageTitle={pageTitle}
                        meta={meta} 
                        metaTitle={metaTitle} 
                        metaDescription={metaDescription} 
                        ogType={ogType} 
                        shareImage={shareImage} />
            <Navigation/>
            <main>{children}</main>
            <Footer/>
            <CookieConsent
                cookieName="OutsmartInsightCookieConsent"
                disableStyles={true}
                buttonClasses="c-btn"
                buttonText="Accept and Close"
                buttonWrapperClasses="c-cookiebar__links"
                containerClasses="c-cookiebar"
            >
                <p className="c-cookiebar__text">
                    We use cookies to give you the best experience of using this website. By continuing to use this site, you accept our use of cookies.
                    Please read our <Link to={pages.cookiePolicy} className="c-cookiebar__link">Cookie Policy</Link> for more information.
                </p>
            </CookieConsent>
        </>
    )
}

Layout.defaultProps = {
    pageTitle: "",
    meta: [],
    metaTitle: "",
    metaDescription: "",
    ogType: "website",
    shareImage: ""
}
  
Layout.propTypes = {
    pageTitle: PropTypes.string,
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    shareImage: PropTypes.string,
    ogType: PropTypes.string
}

export default Layout